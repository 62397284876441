import React, { useEffect, useState, useMemo } from 'react'
import './ContentCard.scss'
import { ReactComponent as TopPerfil } from '../../assets/images/top-up-direction-move-svgrepo-com.svg'
import { ReactComponent as SuccessStock } from '../../assets/images/SuccessStock.svg'
import { ReactComponent as OutOfStock } from '../../assets/images/DeniedStock.svg'
import { ReactComponent as StockTBC } from '../../assets/images/StockTBC.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setAllItemsFavorites, setFavoritesElemQuantityValue } from '../../../../store/slices/allItemsFavorites'
import Favorite from '../../assets/images/heart-linear-card-deactive.svg'
import FavoriteActive from '../../assets/images/CartHeartActive.svg'
import { setCartElemInfo, setItemModal } from '../../../../store/slices/contextUI'
import ContentCardCounter from '../ContentCardCounter/ContentCardCounter'
import useFindInCart from '../../../../hooks/useFindInCart'
import { useParams } from 'react-router'

const ContentCard = ({ card, styledMap }) => {
  const [addFavorite, setAddFavorite] = React.useState(false)
  const dispatch = useDispatch()
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const favorites = useSelector((state) => state.allItemsFavorites.allItemsFavorites)
  const inCart = useFindInCart(card)
  const [itemSettings, setItemSettings] = useState({quantity: 0 | inCart?.quantity, unit: inCart?.currentUnit || card?.unit})

  const categoryParams = useParams().categoryName 
  function isInSeason(seasonRange) {
    const [startMonth, endMonth] = seasonRange.split(' - ').map(Number);
    const currentMonth = new Date().getMonth() + 1; // January is 0-based, so add 1
    return currentMonth >= startMonth && currentMonth <= endMonth;
  }
  
  // Check if the product is in season
  const isProductInSeason = card?.pdt
    ? card.pdt?.split(',').some(isInSeason)
    : true;

    console.log('is season: '+isProductInSeason);
  
  
 

  return ( <div style={styledMap}>
    <div className='contentCard' style={{border: card?.topPerfil?.split(',')?.includes(categoryParams) ? '1px solid #ffc727' : 'none'}}>
      <div className='contentCard__head'>
        {card?.dias ? (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info dias'>{card?.dias} days</div>
          ) : card?.stock == '1' ? (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info stock'>
              <SuccessStock className='contentCard__body__subtitle__info__svg' />
              stock
            </div>
          ) : card?.stock == '0' ? (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info outOfStock'>
              <OutOfStock className='contentCard__body__subtitle__info__svg' />
              stock
            </div>
          ) : (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info TBC'>
              <StockTBC className='contentCard__body__subtitle__info__svg' />
              Confirmar
            </div>
          )}
        {card?.topPerfil?.split(',')?.includes(categoryParams) && <div 
            onMouseEnter={() => dispatch(setCartElemInfo(true))}
            onMouseLeave={() => dispatch(setCartElemInfo(false))}
            className='contentCard__body__subtitle__info TBC'>
            <TopPerfil className='contentCard__body__subtitle__info__svg' />
            TOP
          </div>}
        <span>
          <img
            src={favorites.find((items) => {
              return items.sku === card?.sku
            }) ? FavoriteActive : Favorite}
            className='contentCard__head__heart'
            width='30'
            height='30'
            stroke='#5FC56E'
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setAllItemsFavorites(card))
              dispatch(setFavoritesElemQuantityValue({ value: 1, elem: card }))
              setAddFavorite(!addFavorite)
            }}
          />
        </span>
      </div>
      <div className='contentCard__body' style={{ height: inCart ? 'fit-content' : '100%'}}>
        <div 
          onClick={(e) => {
            e.stopPropagation()
            // setModal(true) 
            // setItemModal({item: card, isShow: true})
            dispatch(setItemModal({item: card, isShow: true}))
          }}
          className='contentCard__body__img'>
            <img src={card?.img} alt="" />
          </div>
        <div className='contentCard__body__title'>{card?.name}</div>
        <div className='contentCard__body__subtitle'>
          <div className='contentCard__body__subtitle__info stock'>{card?.weightValue + ' ' + card?.weightUnits}</div>
          {card?.pdt &&
          <div className='contentCard__body__subtitle__info dias'>{card?.pdt}</div>}
          {card?.spoPrice && <div className='contentCard__body__subtitle__info TBC'>Sale</div>}
        </div>
      </div>
      <div className='contentCard__footer'>
        <div className='contentCard__footer__price'>
          {card?.spoPrice ? card?.spoPrice : card?.marketPriceCP}&euro;/ <span>{card?.currentUnit}</span>
        </div>
      </div>
      <div className='counterMargin'   style={{ marginTop: '10px' }}>
        <ContentCardCounter item={card} key={card?.sku+'counter'}/>
      </div>
    </div>
    </div>
  )
}

export default ContentCard