import React from 'react'
import LogoTPO from '../assets/images/TPOLogoNew.svg'
import WhatsUo from '../assets/images/WhatsupIconLogin.svg'
import TelegIcon from '../assets/images/logos_telegram.svg'
import './Header.scss'
import LoginButton from './Login/LoginButton'
import LoginModal from '../ModalWindows/LoginModal/LoginModal'
import { useDispatch, useSelector } from 'react-redux'
import RegisterModal from '../ModalWindows/RegisterModal/RegisterModal'
import { useHref, useNavigate, useParams } from 'react-router'
import SearchBar from '../Content/SearchBar/SearchBar'
import { setFiltersSection } from '../../../store/slices/filterData'
import useSendLog from '../../../hooks/useSendLog'
import TradeNameSynh from '../AutoDataSynhronaizer/synchronaizers/TradeNameSynh'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const openModal = useSelector(state => state.accModal.accModal.Modal)
  const tradeNameParams = useParams().tradeName
  // console.log(location.pathname.split('/')[1] === 'category');
  const handleGoToTelegram = useSendLog('Click on telegram contact in header', {});
  const handleGoToWhatsup = useSendLog('Click on whatsup contact in header', {});

  const isNY25 = useParams().tradeName == 'NY25' ? true : false

  return (
    <header>
      <TradeNameSynh/>
      <div className='header__left__side'>
        <div className='header__logo'
          onClick={() => {
              if (tradeNameParams !== undefined) {
                if (location.pathname?.split('/')[1] === 'category') {
                  navigate('/home'); location.reload()
                } else {
                  navigate(`/category/${tradeNameParams}`)
                }
              } else {
                navigate('/home'); location.reload()
              }}}
        >
          <img src={LogoTPO} alt='' />
          <span>Tu Producto Online</span>
        </div>
        <SearchBar/>
      </div>
      <div className='header__right__side'>
        {!isNY25 && <LoginButton />}
        <div className='header__phone__number'>
          <img src={TelegIcon} width={50} alt="Telegram"
          onClick={() => {
            window.open('https://t.me/tpobcn', '_blank')
            handleGoToTelegram()
          }}/>
          <img src={WhatsUo} width={60} alt="Whatsapp" 
          onClick={() => {
            window.open('https://wa.me/34642868541', '_blank')
            handleGoToWhatsup()
          }}/>
          <div className='header__phone__number__text'>
            <div style={{ fontSize: '20px' }}>+34 642 868 541</div>
            <div style={{ fontSize: '20px' }}>Soporte</div>
          </div>
        </div>
      </div>
      {openModal === 'Login' && <LoginModal/>}
      {openModal === 'Register' && <RegisterModal/>}
    </header>
  )
}

export default Header
