import React from 'react'
import './ItemModal.scss'
import Favorite from '../../assets/images/CartHeartDesable.svg'
import FavoriteActive from '../../assets/images/CartHeartActive.svg'
import Share from '../../assets/images/Share.svg'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../assets/images/closeElem.svg';

import {
    setAllItemsCart,
    setCartElemQuantityValue,
  } from '../../../../store/slices/allItemsCart'
import { setAllItemsFavorites, setFavoritesElemQuantityValue } from '../../../../store/slices/allItemsFavorites'
import { useNavigate } from 'react-router'

const ItemModal = ({ card, setModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [addFavorite, setAddFavorite] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const handleClick = (PayNumber) => {
    navigator.clipboard.writeText(PayNumber)
    setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};

const action = (
  <React.Fragment>
    <IconButton
    sx={{
        background: '#ffffff',

    }}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <img src={CloseIcon} alt="close" />
    </IconButton>
  </React.Fragment>
);

  const handleShare = () => {
    navigator.clipboard.writeText(`http://localhost:3000/item/${card.sku}`)  
  }

  return (
    <div className='main' onClick={() => setModal(false)}>
      <div className='itemModal'>
        <div className='itemModal__photos'>
          <img src={card.img} alt='' />
        </div>
        <div className='itemModal__baseInfo'>
          <div className='itemModal__baseInfo__title'>{card.name}</div>
          <div className='itemModal__baseInfo__tags'>
            <span>{card.weightValue + ' ' + card.weightUnits}</span>
          </div>
          <div className='itemModal__baseInfo__price'>
            <div className='itemModal__baseInfo__price__price'>
              {card.marketPriceCP}&euro;/ <span>{card.currentUnit}</span>
            </div>
            <div className='itemModal__baseInfo__price__buttons'>
              <img 
                src={addFavorite ? FavoriteActive : Favorite}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(setAllItemsFavorites(card))
                  dispatch(setFavoritesElemQuantityValue({ value: 1, elem: card }))
                  handleClick()
                  setAddFavorite(!addFavorite)
                }}
                style={{ cursor: 'pointer', widows32px: '22px', height: '22px' }}
              ></img>
              <img src={Share} onClick={() => {
                handleShare()
                navigate(`/item/${card.sku}`)
                }}></img>
            </div>
          </div>
        </div>
        <div className='itemModal__separator'></div>
        <div className='itemModal__addition'>
          <div className='itemModal__addition__title'>Additional information:</div>
          <div className='itemModal__addition__tags'>{card.tags}</div>
          <div className='itemModal__addition__brand title'>
            <span>Brand: </span>
            {card.brand}
          </div>
          <div className='itemModal__addition__category title'>
            <span>Category: </span>
            {card.category}
          </div>
          <div className='itemModal__addition__clientType title'>
            <span>Business Type: </span>
            {card.businessType}
          </div>
          <div className='itemModal__addition__structure title'>
            <span>Structure: </span>processed coconut pulp 70%, water, emulsifier E435 0.05%
          </div>
          <div className='itemModal__addition__similar'>
            <span>Similar: </span>
            <div className='itemModal__addition__similar__buttons'>
              <div className='itemModal__addition__similar__buttons__this'>
                <span>This brand</span>
              </div>
              <div className='itemModal__addition__similar__buttons__all'>
                <span>All</span>
              </div>
            </div>
            <div className='itemModal__addition__similar__photos'></div>
          </div>
        </div>
      </div>
        <div className='main__bottom'>
          <div className='main__bottom__button' 
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setAllItemsCart(card))
              dispatch(setCartElemQuantityValue({ value: 1, elem: card }))
              setModal(false)
            }}
          >
            <span>Add to cart</span>
          </div>
        </div>
      
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Id was add in favorite"
        action={action}
        sx={{ zIndex: 100 }}
      />
    </div>
  )
}

export default ItemModal
