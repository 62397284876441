import React, { useEffect, useState } from 'react'
import './SaleMyOrders.scss'
import { salePageApi } from '../../store/services/salePageApi'
import { useNavigate, useParams } from 'react-router'
import { CircularProgress } from '@mui/material'
import { use } from 'i18next'
import SaleBtn from '../components/Buttons/SaleBtn'
import { styled } from '@mui/material/styles';
import OrderElement from './OrderElement/OrderElement'
import useCalcTotal from '../../hooks/SalePageHooks/useCalcTotal'
import SaleTimer from './SaleTimer/SaleTimer'

const SaleMyOrders = () => {
  const navigate = useNavigate()
  const code = useParams().code
  
  // const { saleId } = useParams()
  const saleId = '66d96fc9c9b0239f42e97458'
  const { data: salesData, isLoading: isLoadingSales, refetch } = salePageApi.useGetSalesOrderByIdQuery(
    saleId
  )
  // useEffect(() => {
  //   if (!isLoadingSales) {console.log(salesData);}
  // }, [salesData])
  useEffect(() => {
    refetch()
  }, [])


  
  const userLocalData = JSON.parse(localStorage.getItem('userData'))?.phone?.replace('+', '')
  const [orderslist, setOrderslist] = useState([]);
  useEffect(() => {
    if (!isLoadingSales && code !== 'TpoAll') {
      setOrderslist(salesData?.currentOrders?.filter(el => el.userAcc?.phone === userLocalData));
    } else {
      setOrderslist(salesData?.currentOrders)
    }
  }, [salesData])

  const totalOrdersCost = useCalcTotal(salesData, isLoadingSales, userLocalData)
  useEffect(() => {
    console.log(totalOrdersCost);
  }, [totalOrdersCost])
  const totalCost = totalOrdersCost.reduce((acc, item) => acc + item.total, 0)
  const saleTotalCost = totalOrdersCost.reduce((acc, item) => acc + item.saleTotal, 0)

  return(
    <div className='SaleMyOrders'>
      {!isLoadingSales ? <>
      <div className='SaleMyOrders__head'>
        <div className='SaleMyOrders__headData SaleContainer'>
          <h1>{salesData.title}</h1>
          <p>{salesData.description}</p>
          <div className='SaleMyOrders__headData__info__active'>
            <SaleBtn 
              onClick={() => {navigate(`/group`)}}
              sx={{ width: '270px' }}
            >
              Back to sale
            </SaleBtn>
            <p>last price you will seen when event end!!!</p>
          </div>
        </div>
        <SaleTimer salesData={salesData} isLoadingSales={isLoadingSales}/>
      </div>
      <div className='SaleMyOrders__totalBar SaleContainer'>
        <div className='totalTitle'><h1>Total:</h1> <p>{totalCost.toFixed(2)}€/</p><span className='span'>{saleTotalCost.toFixed(2)}€</span></div>  <span>|</span>
        <div><h1>You have saved:</h1> <span className='span'>{(totalCost-saleTotalCost).toFixed(2)}€</span></div>
      </div>
      <div className='SaleMyOrders__myOrders'>
        {orderslist?.map((el, index) => <OrderElement key={el.id+`${index}`} item={el} totalData={totalOrdersCost[index]}/>)}
        {/* <OrderElement item={'item'}/> */}
      </div></> : <CircularProgress />}
    </div>)
}

export default SaleMyOrders