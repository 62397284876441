import React, { useEffect, useRef, useState } from 'react'
import './SalePage.scss'
import { mainApi } from '../../store/services/mainApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, CircularProgress, IconButton, Snackbar, Typography } from '@mui/material'
import { useDispatch,useSelector } from 'react-redux'
import { setCartModal, setGramMode, setItemModal, setPriceIvaMode, setProMode } from '../../store/slices/contextUI'
import { Switch } from '@mui/material'
import axios from 'axios'
import CarNav from '../components/assets/images/Car-Nav.png'
import moment from 'moment/moment'
import NavAlphaList from '../components/NavAlphaList/NavAlphaList'
import AlpaSortIcon from '../components/assets/images/sortAlfabetIcon.svg'
import useGetConvertedCart from '../../hooks/useGetConvertedCart';
import useReplaceAccent from '../../hooks/useReplaceAccent';
import SalePageElement from './SalePageElement/SalePageElement';
import WarningModal from './../components/ModalWindows/WarningModal/WarningModal';
import ClassicModal from '../components/ModalWindows/ClassicModal/ClassicModal'
import ShareBtnIcon from '../components/assets/images/shareBtnIcon.svg'
import CloseIcon from '@mui/icons-material/Close';
import { salePageApi } from '../../store/services/salePageApi'
import { Description } from '@mui/icons-material'


export default function SalePage() {
  const navigate = useNavigate()
  const tradeName = useParams().tradeName
  const platformParam = useParams().platform
  // const isSalePage = useParams().saleId
  const dispatch = useDispatch()
  const {
    categoryName,
    categoryType,

    } = useParams()
    const isSalePage = '66d96fc9c9b0239f42e97458'
  const view = useSelector((state) => state.contextUI.proMode)

  //  66d96fc9c9b0239f42e97458
  const { data: salesData, isLoading: isLoadingSales } = salePageApi.useGetSalesOrderByIdQuery(
    isSalePage, {pollingInterval: 1000}
  )
  const [updateOrder, { data: result, isSuccess}] = salePageApi.useUpdateSalesOrderByIdMutation()

  const userData = useSelector((state) => state.accountData.userAcc)
  const [id, setId] = React.useState('')
  const isLogin = useSelector((state) => state.contextUI.isLogin)
  useEffect(() => {
    if (tradeName !== '') {
        const tradeId = async () => { 
          const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeName}`)
          setId(responseLogin.data?._id);
          localStorage.setItem('tradeNameCode', responseLogin.data?.tradeName)
          localStorage.setItem('tradeName', responseLogin.data?.name)}
        tradeId() 
      }
  }, [userData, isLogin])

 
  const [eventStartTime, setEventStartTime] = useState(new Date(2024, 8, 7));
  // console.log('eventStartTime: '+eventStartTime);
  const [eventStatus, setEventStatus] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  

  useEffect(() => {
    if (!isLoadingSales) {
      const interval = setInterval(() => {  
        const date = new Date()
        setTimeLeft((salesData?.timeEnd - date.getTime()) / 1000);
        if (timeLeft < 0) {
        setEventStatus(false);
        clearInterval(interval);
        setTimeLeft(0)
        }
        
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoadingSales]);

  async function updateSaleOrder() {
    const now = new Date();
    // console.log(now.getFullYear());
    const hour = 23;
    const minuteStart = 0;
    const minuteEnd = 59;

    now.setHours(0);
    now.setMinutes(minuteStart);
    now.setSeconds(0);
    now.setMilliseconds(0);
    const timeStartConst = now.getTime();
    
    now.setHours(hour);
    now.setMinutes(minuteEnd);
    const timeEndConst = now.getTime();
    await updateOrder({ id: isSalePage, body: {
      timeStart: timeStartConst,
      timeEnd: timeEndConst,
      title: 'Autumn sale 2024',
      description: 'any description for sale event where have some words ',
      // currentOrders: [],
      // salesItems: [
      //   {
      //     _id: "6717c2c289414a6a5e385d5b",
      //     id: 83,
      //     quantity: 0,
      //     img: "https://i.imgur.com/dUMCIN0.jpg",
      //     barcode: "",
      //     name: "Limón para Mesa kg",
      //     sku: "BLEMEkg",
      //     marketPrice: 2.07,
      //     marketPriceDP: 2.2,
      //     marketPriceSDP: 1.86,
      //     marketPriceCP: 2.3,
      //     ourPrice: 1.22,
      //     ourPriceDP: 1.22,
      //     ourPriceSDP: 1.22,
      //     ourPriceCP: 1.22,
      //     MB: 0,
      //     DP: 0,
      //     SDP: 0,
      //     CP: 0,
      //     category: "Frutas",
      //     proveedor: "Cultivar S.A. Unipersonal",
      //     skuProveedor: "LPM",
      //     unit: "kg, und, caja",
      //     dimension: "",
      //     desc: "El precio final será actualizado por el peso del producto",
      //     minQt: 1,
      //     IVA: 2,
      //     IVADP: 2,
      //     IVASDP: 2,
      //     IVACP: 2,
      //     enabled: true,
      //     nameProveedor: "",
      //     status: {},
      //     stock: 1,
      //     dias: null,
      //     currentUnit: "kg",
      //     weightValue: 1,
      //     weightUnits: "kg",
      //     length: null,
      //     width: null,
      //     height: null,
      //     place: null,
      //     shelft: null,
      //     stand: null,
      //     pdt: null,
      //     topPerfil: "Bares",
      //     quantityStep: null,
      //     SPOPrice: null,
      //     brand: null,
      //     businessType: "Bares,Cocina Asiatica,Cocina Italiana,Taquerías Mexicanas,Cevicherias,Laboratorio Bares,Poke,City Cafe",
      //     decimal: 0,
      //     specialization: "Fruits and Vegetables",
      //     section: [
      //         "Kitchen",
      //         "Bar"
      //     ],
      //     priceRange: [
      //       {
      //         price: 2.25,
      //         minQuantity: 0
      //       },
      //       {
      //         price: 2.15,
      //         minQuantity: 100
      //       },
      //       {
      //         price: 1.84,
      //         minQuantity: 200
      //       },
      //       {
      //         price: 1.29,
      //         minQuantity: 300
      //       }
      //     ],
      //     eventStockQuantity: 300,
      //     eventCurrentSalesQuantity: 0,
      //     saleAddStep: 5,
      //   },
      //   {
      //     _id: "6717c2c289414a6a5e385df6",
      //     id: 238,
      //     quantity: 0,
      //     img: "https://i.imgur.com/urfVYQ4.png",
      //     barcode: "",
      //     name: "Aguacate Hass kg",
      //     sku: "AAGHkg",
      //     marketPrice: 4.46,
      //     marketPriceDP: 4.7,
      //     marketPriceSDP: 3.84,
      //     marketPriceCP: 4.95,
      //     ourPrice: 2.3,
      //     ourPriceDP: 2.3,
      //     ourPriceSDP: 2.3,
      //     ourPriceCP: 2.3,
      //     MB: 0,
      //     DP: 0,
      //     SDP: 0,
      //     CP: 0,
      //     category: "Verduras",
      //     proveedor: "G I S DISTRIFRUIT 98 SL",
      //     skuProveedor: "AGUACATE",
      //     unit: "kg, und, caja",
      //     dimension: "",
      //     desc: "El precio final será actualizado por el peso del producto",
      //     minQt: 1,
      //     IVA: 2,
      //     IVADP: 2,
      //     IVASDP: 2,
      //     IVACP: 2,
      //     enabled: true,
      //     nameProveedor: "",
      //     status: {},
      //     stock: 1,
      //     dias: null,
      //     currentUnit: "kg",
      //     weightValue: 1,
      //     weightUnits: "kg",
      //     length: null,
      //     width: null,
      //     height: null,
      //     place: null,
      //     shelft: null,
      //     stand: null,
      //     pdt: null,
      //     topPerfil: "Poke,Cevicheria,Taquerías Mexicanas,Brunch,City Cafe",
      //     quantityStep: null,
      //     SPOPrice: null,
      //     brand: null,
      //     businessType: "Poke,Cevicheria,Taquerías Mexicanas,Brunch",
      //     decimal: 0,
      //     specialization: "Fruits and Vegetables",
      //     section: [
      //         "Kitchen"
      //     ],
      //     priceRange: [
      //       {
      //         price: 4.90,
      //         minQuantity: 0
      //       },
      //       {
      //         price: 4.85,
      //         minQuantity: 100
      //       },
      //       {
      //         price: 4.08,
      //         minQuantity: 200
      //       },
      //       {
      //         price: 3.25,
      //         minQuantity: 300
      //       }
      //     ],
      //     eventStockQuantity: 300,
      //     eventCurrentSalesQuantity: 0,
      //     saleAddStep: 4,
      // },
      // {
      //   _id: "6717c2c289414a6a5e385e70",
      //   id: 360,
      //   quantity: 0,
      //   img: "https://i.imgur.com/ABQFTGi.jpg",
      //   barcode: "",
      //   name: "Tomate Pera kg",
      //   sku: "BTomPerkg",
      //   marketPrice: 2.52,
      //   marketPriceDP: 2.7,
      //   marketPriceSDP: 2.26,
      //   marketPriceCP: 2.8,
      //   ourPrice: 1.33,
      //   ourPriceDP: 1.33,
      //   ourPriceSDP: 1.33,
      //   ourPriceCP: 1.33,
      //   MB: 0,
      //   DP: 0,
      //   SDP: 0,
      //   CP: 0,
      //   category: "Verduras",
      //   proveedor: "NAVARRETE FRUITS S.C.C.L.",
      //   skuProveedor: "TOM PER",
      //   unit: "kg, und",
      //   dimension: "",
      //   desc: "El precio final será actualizado por el peso del producto",
      //   minQt: 1,
      //   IVA: 2,
      //   IVADP: 2,
      //   IVASDP: 2,
      //   IVACP: 2,
      //   enabled: true,
      //   nameProveedor: "",
      //   status: {},
      //   stock: 1,
      //   dias: null,
      //   currentUnit: "kg",
      //   weightValue: 1,
      //   weightUnits: "kg",
      //   length: null,
      //   width: null,
      //   height: null,
      //   place: null,
      //   shelft: null,
      //   stand: null,
      //   pdt: null,
      //   topPerfil: null,
      //   quantityStep: null,
      //   SPOPrice: null,
      //   brand: null,
      //   businessType: "Bares,Cocina Asiatica,Cocina Italiana,Taquerías Mexicanas,Cevicherias,Laboratorio Bares,Poke,City Cafe",
      //   decimal: 0,
      //   specialization: "Fruits and Vegetables",
      //   section: [
      //       "Kitchen"
      //   ],
      //   priceRange: [
      //     {
      //       price: 2.75,
      //       minQuantity: 0
      //     },
      //     {
      //       price: 2.60,
      //       minQuantity: 100
      //     },
      //     {
      //       price: 2.04,
      //       minQuantity: 200
      //     },
      //     {
      //       price: 1.19,
      //       minQuantity: 300
      //     }
      //   ],
      //   eventStockQuantity: 300,
      //   eventCurrentSalesQuantity: 0,
      //   saleAddStep: 5,
      // }
      // ],
    }}).then(res => console.log(res))
    // console.log(result);
  }
  useEffect(() => {
    updateSaleOrder()
  }, [])

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    if (time<=0) {
      return '0:00'
    }

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (timeLeft <= 0) {
      setEventStatus(false);
    } else {
      setEventStatus(true);
    }
  }, [timeLeft]);
  
  // console.log(useGetConvertedCart());
  const [rulesModal, setRulesModal] = useState(false);
  const [copyAlert, setCopyAlert] = useState(false);

  const handleClick = () => {
    setCopyAlert(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopyAlert(false);
  };

  


  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!isLoadingSales) {
      console.log(salesData)
    }
  }, [isLoadingSales])


  return (
    <div className='categoriesMain'>
      {/* <CategoryNews/> */}
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{categoryName}</div>
      </div>
      {!isLoadingSales && salesData !== undefined &&
      <div className='Timer'>
        <span>{!eventStatus ?'Event not started' : 'Ahorra, comprando en grupo!'}</span>
        {/* {formatTime(timeLeft.toFixed(0))} */}
      </div>}
      <Snackbar
        open={copyAlert}
        autoHideDuration={1000}
        onClose={handleClose}
        message='Link was copied'
        action={
          action
        }
        />
      <div 
        className='SalePage__shareBtn'
        onClick={() => {
          navigator.clipboard.writeText('https://tpoapp.com/group')
          handleClick()
        }}
      >
        <div className='Timer'>
          <p>{formatTime(timeLeft.toFixed(0))}</p>
        </div>
        <img src={ShareBtnIcon} alt="" />
        <div className='SalePage__shareBtn__text'>
          Invita vecinos para ahorrar mas
        </div>
      </div>
      {/* {!eventStatus ? <></> : 
        <div className='ShadowPage__search'>
        Buscar en mi lista
          <div style={{display: 'flex', alignItems: 'center'}}>
            <input 
              type="text" 
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div
              className='AlpabetSortBtn'
              onClick={() => {window.scrollTo({top: 251, behavior: 'smooth'})}}
            >
              <img src={AlpaSortIcon} alt="" />
            </div>
            <div 
              className='ShadowPage__search__clearBtn'
              style={{display: searchText !== '' ? 'flex' : 'none'}}
              onClick={() => setSearchText('')}
            >
              X
            </div>
          </div>
        </div>} */}
      <div style={{display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center', marginBottom: '20px'}}>
        <div
          className='ThanksModal__backBtn'
          style={{ marginTop: '0px', color: '#fff', cursor: 'pointer', width: '250px', padding: '0 10px', gap: '10px'}}
          onClick={() => {
            if (isSalePage) {
              navigate('/group/myOrders')
            }
          }}
        >
          Ver pedidos anticipados
          <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 512 512"><path fill="white" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48m-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6m-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36m0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36m0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36"></path></svg>
        </div>
        <div
          className='ThanksModal__backBtn'
          style={{ marginTop: '0px', color: '#fff', cursor: 'pointer', width: '250px', padding: '0 10px', gap: '10px'}}
          onClick={() => {
            if (!isSalePage) {
              if (platformParam !== undefined) {
                navigate(`/newOrder/${platformParam}/${tradeName}`)
              } else {
                navigate(`/newOrder/${tradeName}`);
                window.scrollTo(0, 0)
              }
            } else {
              navigate('/group/newOrder')
            }
          }}
        >
          Ver furgoneta
          <img 
            src={CarNav}
            alt=""
            style={{filter: 'brightness(0) invert(1)', width: '30px'}}
          />
        </div>
      </div>
      {eventStatus && salesData?.salesItems !== undefined && 
      <NavAlphaList list={salesData.salesItems} disabled={true}>
        {isLoadingSales
        ? <CircularProgress/>
        :  [...salesData?.salesItems? salesData.salesItems : []]?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((item) => (
          <div key={item.id} className="NavAlphaList__list-item" data-letter={item.name[0].toUpperCase()}>
            <SalePageElement item={item} key={item.id+'Element'}/>
          </div>
        ))}
      </NavAlphaList>}

      <div
        className='ThanksModal__backBtn'
        style={{margin: 'auto', marginTop: '20px', color: '#fff', cursor: 'pointer'}}
        onClick={() => {
          if (!isSalePage) {
            if (platformParam !== undefined) {
              navigate(`/newOrder/${platformParam}/${tradeName}`)
            } else {
              navigate(`/newOrder/${tradeName}`);
              window.scrollTo(0, 0)
            }
          } else {
            navigate('/group/newOrder')
          }
        }}
      >
        Ver furgoneta
        <img 
          src={CarNav}
          alt=""
          style={{filter: 'brightness(0) invert(1)', width: '30px', marginLeft: '10px'}}
        />
      </div>

      <ClassicModal open={rulesModal} close={setRulesModal}>
        <h1>Reglas e información</h1>
        Esta compra rápida es la esencia en la cual ganar una mayor cantidad de kilogramo para reducir el precio. Se permite compartir un enlace para amigos. El principio se basa en la compra al por mayor, donde el paso es de 5 kg. Cuando confirmamos el pedido, esperamos la venta final, y también le notificamos sobre los precios finales para los cuales se ordenará la mercancía.
        <br/><br/>
        Buena suerte en la venta!!!
      </ClassicModal>
    </div>
  )
}