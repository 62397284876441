import React, { useEffect, useState } from 'react'
import './ShadowPage.scss'
import { mainApi } from '../../store/services/mainApi'
import ContentCard from '../components/Content/ContentCard/ContentCard'
import { useParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@mui/material'
import { useDispatch,useSelector } from 'react-redux'
import { setSearch } from '../../store/slices/search'
import { useLocation, useNavigate } from 'react-router'
import { setCartModal, setGramMode, setItemModal, setPriceIvaMode, setProMode } from '../../store/slices/contextUI'
import { Switch } from '@mui/material'
import axios from 'axios'
import CarNav from '../components/assets/images/Car-Nav.png'
import Slider from '@mui/material/Slider';
import TableCard from '../components/Card/TableCard/TableCard'
import LineCard from '../components/Card/LineCard/LineCard'
import CategoryFilterModal from '../components/ModalWindows/CategoryFilterModal/CategoryFilterModal'
import { setClearCart } from '../../store/slices/allItemsCart'
import NavAlphaList from '../components/NavAlphaList/NavAlphaList'
import AlpaSortIcon from '../components/assets/images/sortAlfabetIcon.svg'
import CarLoader from '../components/CarLoader/CarLoader'
import useSendLog from '../../hooks/useSendLog'
import lodash from 'lodash'
import useChangePageTitle from '../../hooks/useChangePageTitle'
import useReplaceAccent from '../../hooks/useReplaceAccent'
import FastTempleOrder from '../components/FastTempleOrder/FastTempleOrder'
import AlertModal from '../components/ModalWindows/AlertModal/AlertModal'


export default function ShadowPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const tradeName = useParams().tradeName
  const platformParam = useParams().platform
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const { categoryName, categoryType } = useParams()
  const [sortAlphabetProductsData, setSortAlphabetProductsData] = useState([])
  const view = useSelector((state) => state.contextUI.proMode)

  const { data: SPOList, isLoading: isLOadingList } = mainApi.useGetClientPersonalSalesQuery(tradeName)
  const [spoList, setSpoList] = useState([]);
  useEffect(() => {
    if (!isLOadingList) {
      setSpoList(SPOList?.filter((el) => el.selected)?.map((el) => 
        {
          const { selected, ...rest } = el;
          return  rest
        }
      ));
    }
  }, [SPOList])

  // useEffect(() => {
  //   console.log(SPOList);
  // }, [spoList])


  const [personalData, setPersonalData] = useState({})
  useChangePageTitle(tradeName, setPersonalData)
  
  
  const { data: orderData, isLoading: isLoadingOrder } = mainApi.useGetOrderItemsByTradeNameQuery(tradeName)
  const { data: allProductsData, isLoading: isLoadingProducts } = mainApi.useFindAllWordsQuery()


  function replaceItem(item) {
    return {sku: item.sku, name: useReplaceAccent(item.name.toLowerCase())}
  }

  const [searchDataMap, setSearchDataMap] = useState([])
  const [replaceNameData, setReplaceNameData] = useState([])

  useEffect(() => {
    if (sortAlphabetProductsData) {
      setReplaceNameData(sortAlphabetProductsData.map((item) => replaceItem(item)))
    }
  }, [sortAlphabetProductsData])
  const [localProductsFilter, setLocalProductsFilter] = useState(localStorage.getItem('FilteredCatalog') ? JSON.parse(localStorage.getItem('FilteredCatalog')) : []);
  
  const replaceWord = useReplaceAccent(searchText.toLowerCase())
  
  useEffect(() => {
    if (sortAlphabetProductsData && !isLoadingOrder) {
      const filterArray = replaceNameData?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))
      const rr = sortAlphabetProductsData
      ?.filter((el) => filterArray.map((item) => item.sku)
      ?.includes(el.sku))
      ?.filter((el) => !localProductsFilter?.includes(el.sku))?.filter((el) => el?.enabled != false)
      console.log(rr);
    
      if (!isLOadingList && SPOList !== undefined) {
        const spoMapSku = SPOList?.map((el) => el?.sku)
        
        const mergedArray = [...rr, ...SPOList].filter((el) => {
          if (spoMapSku.includes(el?.sku)) {
            if (!el?.spoPrice) {
              return false
            } else {
              if (el?.selected) {
                return false
              } else {
                return true
              }
            }
          } else {
            return true
          }
        });
        console.log('merg');
        console.log(mergedArray);
        setSearchDataMap(mergedArray
        ?.filter((el) => !(tradeName == 'GLB' && el.sku == 'ZumAmazGuayb1lt' && el.sku ==  'LimaCat2KG'))
        ?.filter((el) => el.sku !== 'LimaCat2KG')
        ?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.filter((el) => el.enabled != false))
      } else {
        setSearchDataMap(rr
        ?.filter((el) => !(tradeName == 'GLB') && el.sku == 'ZumAmazGuayb1lt' && el.sku ==  'LimaCat2KG')
        ?.filter((el) => el.sku !== 'LimaCat2KG')
        ?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.filter((el) => el.enabled != false));
      }
    }
  }, [replaceNameData, searchText, localProductsFilter, isLOadingList])




  useEffect(() => {
    if (!isLoadingOrder && orderData !== undefined) {
      const editData = orderData?.map((el) => {
        return {
          ...el,
          quantity: 0,
          quantityOrder: 0
        }
      })
      setSortAlphabetProductsData([...editData].sort(
        function(a, b) {
          if (a.name < b.name) return -1
        }
        ))
    }
  }, [orderData, allProductsData])

  useEffect(() => {
    if (location.pathname === '/home') {
      dispatch(setSearch(''))
    }
  })


  const userData = useSelector((state) => state.accountData.userAcc)
  const [id, setId] = React.useState('')
  const isLogin = useSelector((state) => state.contextUI.isLogin)
  useEffect(() => {
    if (tradeName !== '') {
        const tradeId = async () => { 
          const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeName}`)
          setId(responseLogin.data?.sku);
          const localStorageData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
          localStorage.setItem('userData', JSON.stringify({
            ...localStorageData,
            tradeNameAdress: responseLogin.data?.name,
            tradeNameCode: responseLogin.data?.tradeName,
          }))
        }
        tradeId()
      }
  }, [userData, isLogin])
  useEffect(() => {
    setId()
  }, [
      

  ])
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const [filterCategory, setFilterCategory] = useState(false);
  const [seenAll, setSeenAll] = useState(false);

  const [oldView, setOldView] = useState(view);
  const handleInputChange = useSendLog('Change view of cards in personal link', { 
    changes: {
        view: {old: oldView, new: view},
      }
    });
  const handleAlpabetSortBtn = useSendLog('Click on alphabet navigation', {});
  const handleFilterItemsLink = useSendLog('Click on filter items modal links', {});

  useEffect(() => {
    if ((searchDataMap?.length+spoList?.length)>0) {
      window.location.reload()
    }
  }, [])
  useEffect(() => {
    console.log(searchDataMap);
  }, [searchDataMap])

  const isNY25 = useParams().tradeName == 'NY25' ? true : false
  const [isAlertModal, setIsAlertModal] = useState(isNY25)

  return (
    <div className='categoriesMain' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{categoryName}</div>
      </div>
      {personalData && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', gap: '10px'}}>
        <div style={{width: '50px', height: '50px', borderRadius: '50%', overflow: 'hidden', backgroundImage: `url(${personalData.img})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>

        </div>
        {personalData?.employee}
      </div>}
      <div className='cartModal__header__proMod' style={{fontFamily: 'Montserrat', gap: '30px'}}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '5px'}}>Modo de vista
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            Con fotos 
            <Slider
              step={1}
              min={1}
              max={3}
              value={view}
              onChange={(e) => {
                setOldView(view)
                dispatch(setProMode(e.target.value))
                handleInputChange();
              }}
              sx={{
               width: '80px',
               margin: 'px 20px',
               color: '#5FC56E',
              }}
            />La lista</div>
            </div>
        </div>
        <div className='ShadowPage__search'>
        Buscar en mi lista
          <div style={{display: 'flex', alignItems: 'center'}}>
            <input 
              type="text" 
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div 
              className='ShadowPage__search__clearBtn'
              style={{display: searchText !== '' ? 'flex' : 'none'}}
              onClick={() => setSearchText('')}
            >
              X
            </div>
            <div
              className='AlpabetSortBtn'
              onClick={() => {
                window.scrollTo({top: 251, behavior: 'smooth'})
                handleAlpabetSortBtn()
              }}
            >
              <img src={AlpaSortIcon} alt="" />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <span style={{color: '#A4A4A4', marginLeft: '83px', marginBottom: '-14px'}}>IVA</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <div style={{marginRight: '-6px'}}>
              <span style={{color: '#A4A4A4'}}>Los precios</span> sin
            </div>
            <Switch  
              className='ProfileMobile__status__switch'
              checked={ivaSwitch}
              onChange={() => {dispatch(setPriceIvaMode(!ivaSwitch))}}
              sx={{
                width: '68px',
                '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                    width: '66px'},
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#fff !important',
                    widows: '20px',
                },
                '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#5FC56E !important'},
                '& .MuiSwitch-track': {

                        height: '24px',
                        width: '70px',
                        borderRadius: '14px',

                        transform: 'translateX(-5px) translateY(-5px)'
                    }
                }}
              />
              <div style={{marginLeft: '-16px'}}>
                сon
              </div>
          </div>
          </div>
          <div 
            style={{width: '36px', height: '36px', background: '#5FC56E', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
            onClick={() => {
              setFilterCategory(true);
              handleFilterItemsLink()
            }}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"><g fill="none"><path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"/><path fill="white" d="M11 2a5 5 0 1 0 0 10a5 5 0 0 0 0-10m.123 12.55a1 1 0 0 0-.913-1.525c-2.21.14-4.195.858-5.651 1.813c-.728.478-1.348 1.031-1.796 1.63C2.32 17.057 2 17.755 2 18.5c0 1.535 1.278 2.346 2.495 2.763c1.28.439 2.99.638 4.832.707a1 1 0 0 0 .995-1.29A7.498 7.498 0 0 1 10 18.5a7.46 7.46 0 0 1 1.123-3.95m6.288 6.232a5.348 5.348 0 0 1-.822 0l-.087.325a1 1 0 0 1-1.932-.518l.09-.337c-.24-.12-.473-.26-.693-.42l-.26.259a1 1 0 0 1-1.414-1.414l.33-.331a5.405 5.405 0 0 1-.517-1.202a1 1 0 0 1 1.918-.568c.878 2.963 5.074 2.963 5.952 0a1 1 0 1 1 1.918.568c-.13.44-.306.841-.518 1.202l.331.33a1 1 0 0 1-1.414 1.415l-.26-.26c-.22.16-.453.3-.693.421l.09.337a1 1 0 1 1-1.932.518z"/></g></svg>
          </div>
          <CategoryFilterModal setFilterCategory={setFilterCategory} filterCategory={filterCategory}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '10px', textAlign: 'center', justifyContent: 'center'}}
        >
          Articulos en el catálogo: {searchDataMap?.length+spoList?.length}
        </div>
        <FastTempleOrder />
      {<div className='categoriesPage' style={{justifyContent: 'space-around'}}>
        {!isLOadingList && spoList?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.length > 0 && 
        <div className='SPOBox'>
          <div className='SPOBox__title'>Tu Precio de Oferta</div>
          <div className='SPOBox__list'>
            {view === 1 ? spoList?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => <ContentCard card={item} key={item.title+item.sku}/>)
            : view === 3 ? spoList?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => <LineCard item={item} key={item.title+item.sku}/>) 
            : spoList?.filter((item) => useReplaceAccent(item.name.toLowerCase()).includes(replaceWord))?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => <TableCard item={item} />)}
          </div>
        </div>}
        <NavAlphaList list={!seenAll ? searchDataMap?.sort((a, b) => a.name?.localeCompare(b.name))?.slice(0, 50) : searchDataMap?.sort((a, b) => a.name?.localeCompare(b.name))} view={view} more={seenAll} setMore={setSeenAll}>
          {!seenAll ? 
          isLoadingOrder
          ? <div
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '28px', marginLeft: '60px', fontWeight: '700', color: '#5fc56e', textAlign: 'center'}}
          >
            <CarLoader/>
            <span style={{width: '80%'}}>Por favor espera, se está cargando tu lista de productos</span>
          </div>
          : view === 1 ? searchDataMap?.slice(0, 50).sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()} style={{width: 'fit-content'}}>
              <ContentCard card={item} key={item?.title+item?.sku}/>
            </div>)
          : view === 3 ? searchDataMap?.slice(0, 50).sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()}>
              <LineCard item={item} key={item?.title+item?.sku}/>
            </div>) 
          : searchDataMap?.slice(0, 50).sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()} style={{marginLeft: '20px'}}>
              <TableCard item={item} />
            </div>)
          : isLoadingOrder
          ? <CircularProgress/>
          : view === 1 ? searchDataMap?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()} style={{width: 'fit-content'}}>
              <ContentCard card={item} key={item?.title+item?.sku}/>
            </div>)
          : view === 3 ? searchDataMap?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()}>
              <LineCard item={item} key={item?.title+item?.sku}/>
            </div>) 
          : searchDataMap?.sort((a, b) => a.name?.localeCompare(b.name))?.map((item) => 
            <div key={item?.id} className="NavAlphaList__list-item" data-letter={item?.name[0].toUpperCase()} style={{marginLeft: '20px'}}>
              <TableCard item={item} />
            </div>)}           
        </NavAlphaList>
        {!seenAll && searchDataMap?.length > 50 && <div
          className='ThanksModal__backBtn'
          style={{margin: 'auto', marginTop: '20px', color: '#fff', cursor: 'pointer', width: '100%'}}
          onClick={() => {setSeenAll(true);}}
        >
          Ver todo
        </div>}
      </div>}
      <div
        className='ThanksModal__backBtn'
        style={{margin: 'auto', marginTop: '20px', color: '#fff', cursor: 'pointer'}}
        onClick={() => {if (platformParam !== undefined) {navigate(`/newOrder/${platformParam}/${tradeName}`)} else {navigate(`/newOrder/${tradeName}`);} window.scrollTo(0, 0)}}
      >
        Ver furgoneta
        <img 
          src={CarNav}
          alt=""
          style={{filter: 'brightness(0) invert(1)', width: '30px', marginLeft: '10px'}}
        />
      </div>
      {isAlertModal && 
      <AlertModal closeFunc={setIsAlertModal} sx={{ textAlign: 'start '}}>
        С наступающим Новым Годом!
        <br/>
        <br/>
        Мы принимаем заказы на новогодние салаты и блюда до 26.12.24
        <b>включительно</b>
        <br/>
        <br/>
        Доставка будет осуществляться только 30.12.24
        <br/>
        <br/>
        Все заказы после 26.12.24 по умолчанию на листе ожидания и требуют подтверждение
        <br/>
        С наступающим праздником 🎅
      </AlertModal>}
    </div>
  )
}
