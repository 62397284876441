import React from 'react'
import { useSelector } from 'react-redux'
import CounterModal from '../../ItemCardModal/CounterModal/CounterModal'
import useFindItemByUnit from '../../../../../hooks/useFindItemByUnit'
import useFindInCart from './../../../../../hooks/useFindInCart';

const CartItem = ({ item }) => {
  const proMode = useSelector((state) => state.contextUI.proMode)
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const inCart = useFindInCart(item)
  const total = (item.marketPriceCP*(useFindItemByUnit(item, inCart?.currentUnit || item.unit?.split(',')[0])*(inCart?.quantity || 0))).toFixed(2)
  const totalWIVA = (item.marketPriceCP * useFindItemByUnit(item, inCart?.currentUnit || item.unit?.split(',')[0]) * (inCart?.quantity || 0) /(1 + item.IVACP/100)).toFixed(2)

  return (
    !proMode ? 
    <div className='cartModal__body__item'>
      <div style={{width: '100%'}}>{item.name}</div>
      <CounterModal item={item}/>
      <span>{(item.marketPriceCP*(item.quantity*useFindItemByUnit(item, item.currentUnit))).toFixed(2)}€</span>
    </div>
    : <div className='cartModal__body__item' style={{flexDirection: 'column'}}>
      <div className='cartModal__body__item__row'>
        <img src={item.img} alt="" />
        <div className='cartModal__body__item__row__info'>
          {item.name}
          <div className='cartModal__body__item__row__info__sku'>{item.sku}</div>
        </div>
      </div>
      <div className='cartModal__body__item__row'>
        <CounterModal item={item}/>
        <span>
          {ivaSwitch ? total +'€' : totalWIVA +'€'}
        </span>
      </div>
    </div>
  )
}

export default CartItem