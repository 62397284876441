import React, { useEffect } from 'react'
import './ItemOrderElement.scss'
import useSalePrice from '../../../../hooks/SalePageHooks/useSalePrice';
import { useParams } from 'react-router';
import { salePageApi } from '../../../../store/services/salePageApi';
import useGetUpdateSalePrice from '../../../../hooks/SalePageHooks/useGetUpdateSalePrice';

const ItemOrderElement = ({ item }) => {
  // console.log(item);
  // const saleId = useParams().saleId
  const saleId = '66d96fc9c9b0239f42e97458'
  const { data: salesData, isLoading: isLoadingSales, refetch } = salePageApi.useGetSalesOrderByIdQuery(
    saleId
  )
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
  const updatePrice = useGetUpdateSalePrice(salesData, isLoadingSales, userData.phone)
  // useEffect(() => {
  //   console.log(updatePrice);
  // }, [updatePrice])
  const salePriceResult = (updatePrice.find(el => el.sku === item.sku)?.spoPrice*1).toFixed(2)
  return (
    <div className='ItemOrderElement'>
      <div className='ItemOrderElement__info'>
        <div className='ItemOrderElement__info__img'>
          <img src={item.img} alt="" />
        </div>
        <div className='ItemOrderElement__info__data'>
          <div className='ItemOrderElement__info__data__name'>{item.name}</div>
          <div className='ItemOrderElement__info__data__price'>{salePriceResult}€<span>/paq</span> 
            <div>You have saved: {(item.marketPriceCP-salePriceResult).toFixed(2)}€</div>
          </div>
        </div>
      </div>
      <div className='ItemOrderElement__stats'>
        <div className='ItemOrderElement__stats__quantity'>{item.quantity} paq</div>
        <div className='ItemOrderElement__stats__totalPrice'>
          {(updatePrice.find(el => el.sku === item.sku)?.spoPrice*item.quantity).toFixed(2)}€
          <span>{(item.quantity*item.marketPriceCP).toFixed(2)}€</span>  
        </div>
      </div>
    </div>
  )
}

export default ItemOrderElement