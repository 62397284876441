import React, { useEffect, useState } from 'react'
import './ItemPage.scss'
import ItemModal from '../ModalWindows/ItemModal/ItemModal'
import { useParams } from 'react-router-dom'
import { mainApi } from '../../../store/services/mainApi'
import { CircularProgress, Snackbar } from '@mui/material'
import axios from 'axios'
import ImageModal from '../ModalWindows/ImageModal/ImageModal'
import { useDispatch, useSelector } from 'react-redux'
import useFindInCart from '../../../hooks/useFindInCart'
import useFindItemByUnit from '../../../hooks/useFindItemByUnit'
import { setCartElemInfo, setCartModal, setItemModal } from '../../../store/slices/contextUI'
import { ReactComponent as SuccessStock } from '../../components/assets/images/SuccessStock.svg'
import { ReactComponent as OutOfStock } from '../../components/assets/images/DeniedStock.svg'
import { ReactComponent as StockTBC } from '../../components/assets/images/StockTBC.svg'
import CloseIcon from '../../components/assets/images/Close_Green.svg'
import HeartIcon from '../../components/assets/images/heart-linear-card-deactive.svg'
import HeartIconActive from '../../components/assets/images/CartHeartActive.svg'
import inCartIcon from '../../components/assets/images/Car-Nav.png'
import { setAllItemsFavorites, setFavoritesElemQuantityValue } from '../../../store/slices/allItemsFavorites'
import ItemCounterModal from '../ModalWindows/ItemCardModal/ItemCounterModal/ItemCounterModal'
import CarLoader from './../CarLoader/CarLoader';

const ItemPage = () => {
  const params = useParams()
  const [itemCard, setItemCard] = React.useState({})
  const {
    data: dd,
    isLoading,
    isError: fastOrderError,
  } = mainApi.useGetProductsBySkusQuery({ skus: params.sku },
    {
      refetchOnMountOrArgChange: true,
    },)  

  const featchItem = async () => {
    console.log(dd);
    setItemCard(...dd)
  }
  
  useEffect(() => {
    if (!isLoading) {
      featchItem()
    }
  }, [dd])
  
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.allItemsFavorites.allItemsFavorites)
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const [bigPicture, setBigPicture] = useState(false)
  
  const inCart = useFindInCart(itemCard);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const totalPrice = allItemsCart.map((el) => el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const IVA = allItemsCart.map((el) => (el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit))) - (parseInt(el.IVACP) !== 0 ? 
  ((el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(parseInt(el.IVACP))) : 0))
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)

  return (!isLoading ?
    <div className='ItemPage'>
      <div className='ItemCardModals' style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
      <div className='ItemCardModal__content' onClick={(e) => e.stopPropagation()}>
        {bigPicture && <ImageModal img={itemCard?.img} setClose={setBigPicture} />}
        <div className='ItemCardModal__content__header'>
          {!itemCard?.dias && itemCard?.stock != '0' && itemCard?.stock != '1' &&
          (
            <div 
              onMouseEnter={() => dispatch(setCartElemInfo(true))}
              onMouseLeave={() => dispatch(setCartElemInfo(false))}
              className='contentCard__body__subtitle__info TBC'>
              <StockTBC className='contentCard__body__subtitle__info__svg ' />
              Confirmar
            </div>
          )}
          <div className='ItemCardModal__content__header__img'>
            <img src={itemCard?.img} alt="" onClick={() => setBigPicture(true)}/>
          </div>
          <div className='ItemCardModal__content__header__data'>
            <div className='ItemCardModal__content__header__data__title'>
              {itemCard?.name}
            </div>
            <div className='ItemCardModal__content__header__data__subtitle'>
              <div className='stock'>
                {itemCard?.weightValue + ' ' + itemCard?.weightUnits}
              </div>
              {itemCard?.dias ? (
                <div
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info dias'>{itemCard?.dias} days</div>
              ) : itemCard?.stock == '1' ? (
                <div
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info stock'>
                  <SuccessStock className='contentCard__body__subtitle__info__svg' />
                  stock
                </div>
              ) : itemCard?.stock == '0' && (
                <div 
                  onMouseEnter={() => dispatch(setCartElemInfo(true))}
                  onMouseLeave={() => dispatch(setCartElemInfo(false))}
                  className='contentCard__body__subtitle__info outOfStock'>
                  <OutOfStock className='contentCard__body__subtitle__info__svg' />
                  stock
                </div>
              )}
            </div>
          </div>
          <div className='ItemCardModal__content__header__btn'>
            <img src={CloseIcon} alt="X" onClick={() => dispatch(setItemModal({item: {},  isShow: false}))}/>
          </div>
        </div>
        <div className='ItemCardModal__content__body'>
          <div className='ItemCardModal__content__body__buttons'>
            <div className='ItemCardModal__content__body__buttons__price'>{itemCard?.marketPriceCP}&euro;<span>/ {itemCard?.currentUnit}</span></div>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <img 
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' viewBox='0 0 24 24'%3E%3Cpath fill='%235fc56e' d='m13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.457l5.11 2.788a3.5 3.5 0 1 1-.958 1.755'/%3E%3C/svg%3E"
                alt=""
                style={{cursor: 'pointer'}}
                onClick={async () => {
         
                  if (navigator.share) {
                    try {
                      await navigator.share({
                        title: 'Articulos en el catálogo',
                        url: `https://tpoapp.com/item/${itemCard.sku}`
                      })
                      handleClick()
                    } catch (error) {
                      console.error('Error sharing:', error);
                    }
                  } else {
                    console.log('Web Share API is not supported');
                    navigator.clipboard?.writeText(`https://tpoapp.com/item/${itemCard.sku}`);
                    handleClick()
                  }
                }}
              />
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Link of product was copied"
                sx={{ border: '3px solid #5fc56e', borderRadius: '8px', background: '#fff' }}
              />
              <img 
                src={favorites.find((items) => {
                  return items.sku === itemCard?.sku
                }) ? HeartIconActive : HeartIcon} 
                alt="" 
                onClick={() => {
                  dispatch(setAllItemsFavorites(itemCard))
                  dispatch(setFavoritesElemQuantityValue({ value: 1, elem: itemCard }))
                  // setAddFavorite(!addFavorite)
                }}
              />
            </div>
          </div>
          <div className='ItemCardModal__content__body__counter'>
          <ItemCounterModal item={itemCard} /> 
            <div className='ItemCardModal__content__body__counter__price'>
              <span>Total item: {ivaSwitch ? (itemCard?.marketPriceCP* (useFindItemByUnit(itemCard, inCart?.currentUnit || itemCard?.unit?.split(',')[0])*(inCart?.quantity || itemCard?.quantity))).toFixed(2) +'€' : ((itemCard?.marketPriceCP* (useFindItemByUnit(itemCard, inCart?.currentUnit || itemCard?.unit?.split(',')[0])*(inCart?.quantity || itemCard?.quantity)))/(100+parseInt(itemCard?.IVACP))*100).toFixed(2) +'€'}</span>
            </div>
          </div>
          <div className='ItemCardModal__content__body__info'>
            <div
              className='ItemCardModal__content__body__info__text'
              >Additional information: <span style={{fontWeight: '600'}}>{itemCard?.desc}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Brand: <span>{itemCard?.brand}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Category: <span>{itemCard?.category}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >BusinessType: <span>{itemCard?.businessType}</span>
            </div>
            <div
              className='ItemCardModal__content__body__info__text'
              >Structure: <span></span>
            </div>
          </div>
        </div>
        <div className='ItemCardModal__content__footer'>
          <div 
          className='ItemCardModal__content__footer__continueBtn'
          onClick={() => {
            dispatch(setItemModal({item: {},  isShow: false}))
            dispatch(setCartModal(true))
          }} 
          style={{color: '#5fc56e', borderColor: '#5fc56e'}}
        >
          <img src={inCartIcon} alt="" />
          <pre> </pre>
          {ivaSwitch ? totalPrice.toFixed(2) : IVA.toFixed(2)}€
        </div>
        <div 
          className='ItemCardModal__content__footer__addCartBtn'
          onClick={() => dispatch(setItemModal({item: {},  isShow: false}))} 
        >
          Continue
        </div>
        </div>
      </div>
    </div>
    </div>
    : <div style={{width: '380px', height: '150px', background: '#5fc56e', margin: 'auto'}}><CarLoader/></div>
  )
}

export default ItemPage