import React, { useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import './CartModal.scss'
import ArrowDownGreen from '../../assets/images/ArrowDown_Geen.svg'
import CounterModal from '../ItemCardModal/CounterModal/CounterModal'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, dispatch, useDispatch } from 'react-redux'
import { setCartModal, setPaymentMetod } from '../../../../store/slices/contextUI'
import {
  Checkbox,
} from '@mui/material'
import { setProMode } from '../../../../store/slices/contextUI'
import CartItem from './CartItem.jsx/CartItem'
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit'

const CartModal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const allItemsCart = useSelector((store) => store.allItemsCart.allItemsCart)
  const [sortAlphabetCartData, setSortAlphabetCartData] = useState([])

  const proMode = useSelector((state) => state.contextUI.proMode)
  const [showMetods, setShowMethods] = useState(false)
  const method = useSelector((state) => state.contextUI.paymentMetod)
  const total = allItemsCart.map((el) => el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const totalWIVA = allItemsCart.map((el) =>((el.marketPriceCP * el.quantity*useFindItemByUnit(el, el.currentUnit))/(1 + el.IVACP/100)))
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  
  const percentageOrder = {
    pickUp: -15,
    payOnline: -1.5,
    creditLine: 1
  }
  
  const pickUpPrice = {
    total: ivaSwitch ? total + total / 100 * percentageOrder.pickUp : totalWIVA + totalWIVA / 100 * percentageOrder.pickUp,
    save: ivaSwitch ? total / 100 * percentageOrder.pickUp : totalWIVA / 100 * percentageOrder.pickUp,
  }
  const payOnlinePrice = {
    total: ivaSwitch ? total + total / 100 * percentageOrder.payOnline : totalWIVA + totalWIVA / 100 * percentageOrder.payOnline,
    save: ivaSwitch ? total / 100 * percentageOrder.payOnline : totalWIVA / 100 * percentageOrder.payOnline,
  }
  const CreditLinePrice = {
    total: ivaSwitch ? total + total / 100 * percentageOrder.creditLine : totalWIVA + totalWIVA / 100 * percentageOrder.creditLine,
    save: ivaSwitch ? total / 100 * percentageOrder.creditLine : totalWIVA / 100 * percentageOrder.creditLine,
  }
  
  useEffect(() => {
    setSortAlphabetCartData([...allItemsCart].sort(
    function(a, b) {
      if (a.name < b.name) return -1
    }
    ))
  }, [allItemsCart])


  return (
    <div className='cartModal__background' onClick={() => dispatch(setCartModal(false))}>
      <div className='cartModal' onClick={(e) => e.stopPropagation()}>
        <div className='cartModal__header'>
          <div className='cartModal__header__back' onClick={() => dispatch(setCartModal(false))}>
            <img src={ArrowDownGreen} style={{transform: 'rotate(90deg)'}} alt="" />
            Back
          </div>
          <div className='cartModal__header__proMod'>
            Pro Mode (Sin Foto)
            <Switch
              className=''
              checked={!proMode}
              onClick={() => {dispatch(setProMode(!proMode))}}
              sx={{
                width: '68px',
                '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                  width: '66px'},
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#fff',
                  widows: '20px',
                },
                '& .Mui-checked+.MuiSwitch-track': {
                  backgroundColor: '#5FC56E !important'},
                '& .MuiSwitch-track': {
                  height: '24px',
                  width: '70px',
                  borderRadius: '14px',
                  transform: 'translateX(-5px) translateY(-5px)'
                }
              }}
              />
          </div>
        </div>
        <div className='cartModal__body'>
          {sortAlphabetCartData.map((item) => (
            <CartItem item={item}/>
          ))}
        </div>
        <div className='cartModal__footer'>
          <div className='cartModal__footer__delivery'>
            <div 
              className='cartModal__footer__delivery__title'
              onClick={() => {setShowMethods(!showMetods)}}  
            >
              Payment type: {method}
              <img src={ArrowDownGreen} alt="" />
            </div>
            {showMetods && <div className='cartModal__footer__delivery__methods'>
              <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
                    checked={method === 'Pick Up'}
                    onClick={() => {
                      localStorage.setItem('paymentMethod', 'Pick Up')
                      dispatch(setPaymentMetod('Pick Up'))
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pick Up <span>Mercabarna</span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {pickUpPrice.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {pickUpPrice.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        {percentageOrder.pickUp}% <span style={{fontSize: '10px'}}>(coming soon)</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
                    checked={method === 'Pay Online'}
                    onClick={() => {
                      localStorage.setItem('paymentMethod', 'Pay Online')
                      dispatch(setPaymentMetod('Pay Online'))
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pay Online
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {payOnlinePrice.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {payOnlinePrice.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        {percentageOrder.payOnline}%
                      </div>
                    </div>
                  </div>
                </div>

                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
                    checked={method === 'Credit Line'}
                    onClick={() => {
                      localStorage.setItem('paymentMethod', 'Credit Line')
                      dispatch(setPaymentMetod('Credit Line'))
                  }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Credit Line
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {CreditLinePrice.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You not save</div>
                        <span>
                          +{CreditLinePrice.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        +{percentageOrder.creditLine}%
                      </div>
                    </div>
                  </div>
                </div>
            </div>}
          </div>
          <div className='cartModal__footer__total'>
            Total
            <span>{ivaSwitch ? total.toFixed(2) : totalWIVA.toFixed(2)}€</span>
            <div 
              className='cartModal__footer__total__btn'
              style={{opacity: allItemsCart.length > 0 ? 1 : 0.5}}
              onClick={() => {allItemsCart.length > 0 && navigate('/newOrder')
                allItemsCart.length > 0 && dispatch(setCartModal(false))
              }}>
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartModal