import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CloseIcon from '../../assets/images/closeElem.svg'

const AlertModal = ({children, timeout=null, closeFunc, sx}) => {
  console.log(timeout);
  useEffect(() => {
    if (timeout !== null) {
      setTimeout(() => {
        closeFunc(false)
      }, timeout)
    }
  }, [])

  return (
    <div className='WarningModal' onClick={() => closeFunc(false)}>
      <div className='WarningModal__content'style={{ border: '1px solid #5fc56e'}}>
        <div className='WarningModal__content__close'>
          <img src={CloseIcon} alt="" onClick={() => closeFunc(false)}/>
        </div>
        <div className='WarningModal__content__children' style={sx}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AlertModal